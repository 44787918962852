<template>
<div class="fit sticky-form-actions">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <q-icon name="add" class="mr-1" />{{ translate('add_location') }}
            </template>
            <b-list-group class="list-group-accent">
                <b-form @submit.prevent class="full-height">
                    <b-list-group-item class="p-0 pt-2 pb-2">
                        <div class="row m-0">
                            <div class="col-md p-0">
                                <q-input :placeholder="translate('name')" dense square color="primary" type="text" id="name" v-model="location.name" />
                            </div>
                            <div class="col-md p-0">
                                <q-input :placeholder="translate('address')" dense square color="primary" type="text" id="address" v-model="location.address" />
                            </div>
                        </div>
                        <div>
                            <q-input :placeholder="translate('description')" v-model="location.description" square dense color="primary" type="textarea" rows="2" />
                        </div>
                        <div class="row m-0">
                            <div class="col p-0">
                                <q-input :label="translate('start_time_open')" color="primary" square dense v-model="location.start_time" emit-value>
                                    <template v-slot:append>
                                        <q-icon name="access_time" class="cursor-pointer">
                                            <q-popup-proxy transition-show="scale" transition-hide="scale">
                                                <q-time now-btn class="time-menu" v-model="location.start_time" mask="HH:mm" format24h />
                                            </q-popup-proxy>
                                        </q-icon>
                                    </template>
                                </q-input>
                            </div>
                            <div class="col p-0">
                                <q-input :label="translate('end_time_close')" color="primary" square dense v-model="location.end_time" emit-value>
                                    <template v-slot:append>
                                        <q-icon name="access_time" class="cursor-pointer">
                                            <q-popup-proxy transition-show="scale" transition-hide="scale">
                                                <q-time now-btn class="time-menu" v-model="location.end_time" mask="HH:mm" format24h />
                                            </q-popup-proxy>
                                        </q-icon>
                                    </template>
                                </q-input>
                            </div>
                        </div>
                        <div class="row m-0 pl-3 pr-3">
                            <div class="col p-0">
                                <small class="text-uppercase">{{ translate('images') }}</small>
                                <b-form-file type="file" name="selectedFiles" ref="selectedFiles" multiple @change="onFilesSelected" v-model="selectedFiles" />
                            </div>
                        </div>
                    </b-list-group-item>
                </b-form>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section side>
                            <q-btn type="submit" no-caps color="info" @click.prevent="registerLocation">
                                <q-icon size="1rem" name="check" class="mr-1" /> {{ translate('save') }}
                            </q-btn>
                        </q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import _ from 'lodash'

export default {
    name: 'AddLocation',
    props: ['add_location'],
    data: function () {
        return {
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,

            location: {
                name: null,
                address: null,
                description: '',
                start_time: '',
                end_time: '',
            },
            selectedFiles: null,
            msg: null
        };
    },
    created: function () {
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        onFilesSelected: function (event) {
            this.selectedFiles = event.target.files[0]
        },
        registerLocation: function () {
            const formData = new FormData()

            if (this.selectedFiles) {
                this.selectedFiles.map((file, index) => {
                    formData.append(`file${index}`, file);
                })
            }

            if (this.location.name) {
                formData.set('name', this.location.name)
            }
            if (this.location.address) {
                formData.set('address', this.location.address)
            }
            if (this.location.description) {
                formData.set('description', this.location.description)
            }
            if (this.location.start_time) {
                formData.set('start_time', this.location.start_time + ':00')
            }
            if (this.location.end_time) {
                formData.set('end_time', this.location.end_time + ':00')
            }

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'multipart/form-data'
            };

            axios.post(baseUrl + 'locations', formData, {
                    headers: headers
                })
                .then(response => {
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'add_location_' + response.data.msg + key
                    var translated_message = this.translate(message)
                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            status: true
                        })                        
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(error => {
                    // console.log(error)
                });
        },
        closePanel: function () {
            this.$emit('closePanel');
        }
    }
};
</script>
