<template>
<div>
    <h3 class="font-weight-bold text-uppercase small"><i class="text-blue-8 fas fa-images"></i> {{ translate('images') }}</h3>

    <q-list v-if="item_images" class="row text-center m-0">
        <!-- Main image: image with index 0 -->
        <custom-image container_classes="col-xs-6 col-sm-3 col-md p-0" :entity_type="entity_type" :item_id="item_id" :get_route="get_route" :post_route="post_route" :delete_route="delete_route" :image_index="0" :image="item_images.filter((image) => image.image_index === 0)[0]" />
        
        <!-- Second image: image with index 1 -->
        <custom-image container_classes="col-xs-6 col-sm-3 col-md p-0" :item_id="item_id" :get_route="get_route" :post_route="post_route" :delete_route="delete_route" :image_index="1" :image="item_images.filter((image) => image.image_index === 1)[0]" />

        <!-- Third image: image with index 2 -->
        <custom-image container_classes="col-xs-6 col-sm-3 col-md p-0" :item_id="item_id" :get_route="get_route" :post_route="post_route" :delete_route="delete_route" :image_index="2" :image="item_images.filter((image) => image.image_index === 2)[0]" />

        <!-- Fourth image: image with index 3 -->
        <custom-image container_classes="col-xs-6 col-sm-3 col-md p-0" :item_id="item_id" :get_route="get_route" :post_route="post_route" :delete_route="delete_route" :image_index="3" :image="item_images.filter((image) => image.image_index === 3)[0]" />

        <!-- Fifth image: image with index 4 -->
        <custom-image container_classes="col-xs-6 col-sm-3 col-md p-0" :item_id="item_id" :get_route="get_route" :post_route="post_route" :delete_route="delete_route" :image_index="4" :image="item_images.filter((image) => image.image_index === 4)[0]" />
    </q-list>
</div>
</template>

<script>
import CustomImage from './CustomImage'

export default {
    name: 'CustomImages',
    props: ['item_id', 'get_route', 'post_route', 'delete_route', 'item_images', 'entity_type'],
    components: {
        'custom-image': CustomImage
    },
}
</script>
