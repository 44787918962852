<template>
<div :class="container_classes" class="image-uploader-wrapper">
    <div class="image-uploader-container">
        <template v-if="custom_image">
            <img :src="custom_image.image_name" :alt="custom_image.image_name" />
            <q-item class="image-upload-overlay" dark dense>
                <q-item-section class="text-left position-relative">
                    <b-form action="javascript:void(0)" method="post" class="replace-form" :class="'form-' + image_index">
                        <b-form-file 
                            accept="image/*"
                            type="file" 
                            :class="'file-uploader file-uploader-' + image_index"
                            name="file" 
                            ref="selectedFile" 
                            @change="onFileSelected" 
                            v-model="selectedFile">
                        </b-form-file>
                        <q-btn @click.prevent="replaceImage()" dense round flat icon="edit" color="blue" />
                    </b-form>
                </q-item-section>
                <q-item-section>
                    <q-btn @click.prevent="setFullscreen(true)" dense round flat size="1rem" icon="fullscreen" color="white" />
                </q-item-section>
                <q-item-section side class="text-right">
                    <q-btn @click.prevent="showDeleteImagePanel()" dense round flat icon="delete" color="danger" />
                </q-item-section>
            </q-item>
            <template v-if="fullscreen">
                <div class="fullscreen-custom-image-wrapper">
                    <div @click="setFullscreen(false)" class="fullscreen-custom-image-container">
                        <img :src="custom_image.image_name" :alt="custom_image.image_name" />
                    </div>
                </div>
            </template>
        </template>
        <template v-if="!custom_image">
            <b-form action="javascript:void(0)" method="post" :class="'form-' + image_index">
                <b-form-file 
                    accept="image/*"
                    type="file" 
                    class="file-uploader"
                    name="file" 
                    ref="selectedFile" 
                    @change="onFileSelected" 
                    v-model="selectedFile">
                </b-form-file>
                <div v-if="!selectedFile" class="no-image d-flex align-items-center justify-content-center full-width full-height">
                    <i class="fa fa-3x fa-photo"></i>
                </div>
            </b-form>
        </template>
    </div>
</div>
</template>

<script>
import _ from 'lodash'
import { eventBus } from '../../main'
import DeleteImage from './forms/DeleteImage'

export default {
    name: 'CustomImage',
    props: ['container_classes', 'image', 'get_route', 'post_route', 'delete_route', 'item_id', 'image_index', 'entity_type'],
    data: function () {
        return {
            fullscreen: false,
            custom_image: this.image ? this.image : null,
            selectedFile: null,
        }
    },
    methods: {
        replaceImage: function () {
            document.querySelector('.file-uploader-' + this.image_index + ' input').click()
        },
        setFullscreen: function (option) {
            this.fullscreen = option
            if (option === true) {
                document.querySelector('.slideout').classList.add('overflow-hidden')
            } else {
                document.querySelector('.slideout').classList.remove('overflow-hidden')
            }
        },
        onFileSelected: function (event) {
            this.selectedFile = event.target.files[0]
            this.selectedFile.image_name = this.selectedFile.name.split(' ').join('_')
            this.uploadImage()
        },
        getImage: function (target_id, image_index) {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            var url = this.get_route + '/' + target_id

            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    this.custom_image = response.data.item.images.map(image => {
                        image['image_name'] = baseUrl + image.image_name
                        return image
                    }).filter((image) => image.image_index === image_index)[0]
                    if (this.image_index != null && this.image_index === 0) {
                        // Update main_image_link
                        eventBus.$emit('update_image_' + this.entity_type, {id: this.item_id, image_link: this.custom_image.image_name})
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        uploadImage: function () {
            const formData = new FormData()
            var file_name = this.selectedFile.name.split(' ').join('_')
            formData.append('file', this.selectedFile, file_name)
            formData.set('target_id', this.item_id)
            if (this.image_index !== null) {
                formData.set('image_index', parseInt(this.image_index))
            }

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'multipart/form-data'
            }
            axios.post(this.post_route, formData, {
                    headers: headers
                })
                .then(response => {
                    var key = response.data.key ? response.data.key : ''
                    var message = 'images_' + response.data.msg + key
                    var translated_message = this.translate(message)
                    
                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.getImage(this.item_id, this.image_index)
                        this.selectedFile = null
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }

                })
                .catch(error => {
                    // console.log(error)
                })
        },
        showDeleteImagePanel: function () {
            var custom_image = this.custom_image
            
            const panelInstance = this.$showPanel({
                component: DeleteImage,
                props: {
                    image_index: this.image_index,
                    target_id: this.item_id,
                    delete_route: this.delete_route,
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.custom_image = null
                        // Update main_image_link
                        eventBus.$emit('update_image_' + this.entity_type, {id: this.item_id, image_link: null})
                    }
                })
        },
    },
}
</script>

<style lang="scss" scoped>
.image-uploader-wrapper {
    .image-uploader-container {
        border: 1px solid #e2e2e2;
        width: 100%;
        // height: 100px;
        height: 0;
        padding-top: 591.44px / 1127.34px * 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: all .2s ease-in-out;
        word-break: break-word;
        overflow: hidden;
        position: relative;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        .image-upload-overlay {
            position: absolute;
            bottom: 0;
            z-index: 2;
            background: rgba(0, 0, 0, .75);
            transition: all .3s ease-in-out;
            opacity: 0;
            pointer-events: none;
        }

        form {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 0;
            left: 0;
        }

        .file-uploader {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 1;
            display: flex;
            height: 100%;
            flex-direction: column;
            border-radius: 0;
            opacity: 0;
        }

        form.replace-form {
            width: 34px; 
            height: 34px; 
            overflow: hidden; 
            border-radius: 50%;

            .file-uploader {
                z-index: -1;
            }
        }
        
        .no-image {
            transition: all .2s ease-in-out;
            pointer-events: none;
        }
        &:hover {
            box-shadow: 0 3px 6px rgba(0, 0, 0, .36);
            z-index: 1;

            .no-image {
                background-color: rgba(0, 0, 0, .05);
                color: var(--q-color-primary);
            }
        }
    }
    &:hover .image-upload-overlay {
        opacity: 1;
        pointer-events: visibleFill;
    }
}
.fullscreen-custom-image-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    padding: 20px;
    background: rgba(0, 0, 0, .85);
    overflow: auto;

    .fullscreen-custom-image-container {
        max-width: 100%;
        height: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
            position: relative;
            width: auto;
            max-width: 100%;
            height: auto;
            max-height: 100%;
        }
    }
}

.selected-file-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1001;
    width: 100%;
    height: 100%;
    background: #fff;
}

</style>
