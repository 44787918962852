<template>
<div>
    <b-card class="m-0" no-body>
        <div slot="header">
            <h3> {{translate('locations')}}</h3>
        </div>
        <search-wrapper-card :active="location_active" :activeStatus="activeStatus" :inactive="location_inactive" :inactiveStatus="inactiveStatus" :typeDisable="false" form_type="locations">
            <q-input slot="search_input" bottom-slots @input="isTyping = true" v-model="searchQuery" id="filternamelocation" class="search-input" type="text" :placeholder="translate('find_location')" :dense="true">
                <template v-slot:prepend>
                    <q-icon name="search" />
                </template>
            </q-input>
            <template slot="add_button">
                <q-btn v-if="!$q.screen.lt.sm" @click.stop.prevent="showAddLocationPanel" type="button" no-caps color="primary">
                    <i class="fas fa-plus mr-1"></i> {{translate('add_location')}}
                </q-btn>
                <q-btn v-if="$q.screen.lt.sm" class="bg-white text-primary" icon="add" dense round no-caps color="primary" type="button" @click.stop.prevent="showAddLocationPanel" />
            </template>
        </search-wrapper-card>
    </b-card>

    <div class="list_wrapper-locations-list">
        <draggable v-model="locations" group="locations" handle=".handle" class="list-group" v-bind="dragOptions" @start="startDrag" @end="endDrag" @change="setItemPosition" :sort="true">
            <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                <q-item v-for="(location, i) in locations" :key="'location-item-' + i + '-' + (i.id,location.id)" :id="location.id" class="list-item full-width draggable-item" 
                    :class="location.is_active ? 'location-active' : 'location-inactive'">
                    <q-item-section v-if="!$q.screen.lt.sm" side class="pl-0 handle">
                        <q-btn size="sm" flat color="dark" icon="fas fa-grip-horizontal" class="bg-blue-2 cursor-move full-width full-height" :label="i+1" stack>
                            <q-tooltip :offset="[10, 10]">{{ translate('drag_and_drop_to_change_order') }}</q-tooltip>
                        </q-btn>
                    </q-item-section>
                    <q-item-section class="p-0" side>
                        <div class="image-thumbnail cursor-pointer handle" @click.prevent="showEditLocationPanel(location.id, location.is_active)">
                            <img v-if="location.main_image_link" :src="location.main_image_link" />
                            <i v-else class="fa fa-photo fa-2x"></i>
                        </div>
                    </q-item-section>
                    <q-item-section class="full-height align-self-center pt-2 pb-2">
                        <strong class="text-muted">{{ location.name }}</strong>
                    </q-item-section>
                    <!-- [+] Desktop sections. -->
                    <template v-if="!$q.screen.lt.sm">
                        <q-item-section side class="full-height align-self-center pt-2 pb-2">
                            <q-btn round icon="edit" color="primary" @click="showEditLocationPanel(location.id, location.is_active, location.name)">
                                <q-tooltip :offset="[10, 10]">{{ translate('edit_' + entity_type) }}</q-tooltip>
                            </q-btn>
                        </q-item-section>
                        <q-item-section side class="full-height align-self-center pt-2 pb-2">
                            <q-btn round icon="delete" color="danger" @click="showDeleteLocationPanel(location.id)">
                                <q-tooltip :offset="[10, 10]">{{ translate('delete_' + entity_type) }}</q-tooltip>
                            </q-btn>
                        </q-item-section>
                        <q-item-section side class="full-height align-self-center pt-2 pb-2">
                            <toggle-active :is_active="location.is_active" :no_label="true" :patch_toggle_route="patch_toggle_route" :item_id="location.id" :entity_type="entity_type" />
                        </q-item-section>
                    </template>
                    <!-- [-] Desktop sections. -->
                </q-item>
            </transition-group>
        </draggable>
    </div>
    <infinite-loading slot="append" :identifier="'locations-' + infLocations" @infinite="getLocations" />
</div>
</template>

<script>
import _ from 'lodash'
import { eventBus } from '../../../main'
import SearchWrapperCard from '../../template-parts/SearchWrapperCard'
import EditLocation from './forms/EditLocation'
import DeleteLocation from './forms/DeleteLocation'
import AddLocation from './forms/AddLocation'
import draggable from 'vuedraggable'
import ToggleActive from '../../template-parts/ToggleActive'

export default {
    name: 'Locations',
    components: {
        'search-wrapper-card': SearchWrapperCard,
        'toggle-active': ToggleActive,
        draggable
    },
    data: function () {
        return {
            drag: false,
            locations: [],
            searchQuery: '',
            order_by: '',
            location_active: 'active',
            location_inactive: '',
            page: 1,
            isTyping: false,
            state: {},
            infLocations: +new Date()
        }
    },
    mounted: function () {
        eventBus.$on('update_image_location', (image) => {
            this.updateMainImageLink(image)
        })
        eventBus.$on('toggle_active_location', (item) => {
            this.toggleActiveLocation(item)
        })
        eventBus.$on('delete_location', (id) => {
            this.deleteLocation(id)
        })
    },
    computed: {
        entity_type: function () {
            return 'location'
        },
        patch_toggle_route: function () {
            return baseUrl + 'locations'
        },
        dragOptions() {
            return {
                animation: 0,
                group: "locations",
                disabled: false,
                ghostClass: "ghost"
            }
        },
    },
    watch: {
        searchQuery: _.debounce(function () {
            this.isTyping = false
        }, 200),
        isTyping: function (value) {
            if (!value) {
                this.resetMainList()
            }
        }
    },
    methods: {
        updateLocationName: function (item) {
            // Find index of specific object using findIndex method,
            var objIndex = this.locations.findIndex((obj => obj.id === item.location.id))
            // and update the element from the locations array.
            this.locations[objIndex]['name'] = item.location.name ? item.location.name : ''
        },
        toggleActiveLocation: function (item) {
            if (item.id !== null) {
                // Find index of specific object using findIndex method,
                var objIndex = this.locations.findIndex((obj => obj.id === item.id))
                if (this.locations[objIndex]) {
                    // And update is_active for the given item in list.
                    this.locations[objIndex]['is_active'] = item.is_active ? true : false
                    eventBus.$emit('update_toggle_' + this.entity_type, item)

                    if (item.is_active === 0 && this.location_active === 'active' && this.location_inactive === '') {
                        this.deleteLocation(item.id)
                    }
                    if (item.is_active === 1 && this.location_active === '' && this.location_inactive === 'inactive') {
                        this.deleteLocation(item.id)
                    }
                }
            }
        },
        deleteLocation: function (id) {
            // Find index of specific object using findIndex method,
            var objIndex = this.locations.findIndex((obj => obj.id === id))
            // and remove the element from the locations array.
            this.locations.splice(objIndex, 1)
        },
        updateMainImageLink: function (image) {
            if (image.id !== null) {
                // Find index of specific object using findIndex method,
                var objIndex = this.locations.findIndex((obj => obj.id === image.id))
                // And update main_image_link for the given item in list.
                if (!this.locations[objIndex]['main_image_link']) {
                    this.locations[objIndex]['main_image_link'] = ''
                }
                this.locations[objIndex]['main_image_link'] = image.image_link
            }
        },
        resetMainList: function () {
            this.page = 1
            this.locations = []
            this.infLocations++
        },
        startDrag: function (element, event) {
            this.isDragging = true
        },
        endDrag: function (element) {
            this.isDragging = false
        },
        getDropIndex: function (event) {
            var drop_index = event.oldIndex
            if (event.newIndex > event.oldIndex + 1) {
                drop_index = event.newIndex - 1
            }
            if (event.newIndex < event.oldIndex - 1) {
                drop_index = event.newIndex + 1
            }
            return drop_index
        },
        setItemPosition: function (event) {
            var location_drag_id = event.moved.element.id
            var locations = this.locations
            var accessToken = this.accessToken()
            var drop_index = this.getDropIndex(event.moved)
            var location_drop_id = locations[drop_index].id

            var data = {
                "location_drag_id": location_drag_id,
                "location_drop_id": location_drop_id
            }
            var headers = {
                'Authorization': 'Bearer ' + accessToken,
            }
            axios.post(baseUrl + 'locations/position', data, {
                    headers: headers
                })
                .then(response => {
                    this.msg = response.data.msg
                    if (response.data.status) {
                        this.$toasted.success('The positions of your locations have been updated', {
                            duration: this.$toasted_duration
                        })
                    } else {
                        this.$toasted.error('The data was not updated', {
                            duration: this.$toasted_duration
                        })
                    }
                })
        },
        activeStatus: _.debounce(function () {
            if (this.location_active === 'active') {
                this.location_active = ''
            } else {
                this.location_active = 'active'
            }
            this.resetMainList()
        }, 200),
        inactiveStatus: _.debounce(function () {
            if (this.location_inactive === 'inactive') {
                this.location_inactive = ''
            } else {
                this.location_inactive = 'inactive'
            }
            this.resetMainList()
        }, 200),
        getLocations: function ($state) {
            var locationUrl = baseUrl +
                'locations?page=' + this.page +
                '&items_per_page=' + this.$items_per_page
                
            if (this.searchQuery) {
                locationUrl += '&search=' + this.searchQuery
            }
            if (this.location_active === 'active' && this.location_inactive === '') {
                locationUrl += '&location_status=' + this.location_active
            }
            if (this.location_active === '' && this.location_inactive === 'inactive') {
                locationUrl += '&location_status=' + this.location_inactive
            }

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }

            this.state = $state
            axios.get(locationUrl, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        var items = response.data.items.map(item => {
                            if (item.main_image_link) {
                                item['main_image_link'] = baseUrl + item.main_image_link
                            }
                            return item
                        })
                        this.locations.push(...items)
                    }
                    if (response.data.next_page === true) {
                        this.page++
                        $state.loaded()
                    } else {
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        showDeleteLocationPanel: function (id) {
            const panelInstance = this.$showPanel({
                component: DeleteLocation,
                props: {
                    location_id: id
                }
            })
        },
        showAddLocationPanel: function () {
            const panelInstance = this.$showPanel({
                component: AddLocation,
            })

            panelInstance.promise.then(result => {
                if (result && result.status) {
                    this.resetMainList()
                }
            })
        },
        showEditLocationPanel: function (id, is_active) {
            const panelInstance = this.$showPanel({
                component: EditLocation,
                props: {
                    location_id: id,
                    entity_type: this.entity_type,
                    patch_toggle_route: this.patch_toggle_route,
                    is_active: is_active
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.updateLocationName(result)
                    }
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.flip-list-move {
    transition: transform 0.5s;
}

.no-move {
    transition: transform 0s;
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
    transform-origin: center;
    transform: scale(.5);
}

.search-input {
    margin-left: -15px !important;
}

.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: 0 0 .5rem;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    transition: border-color .1s ease-in-out;

    &:first-child {
        margin-top: .5rem;
    }

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }

    &.draggable-item:hover {
        border-style: dotted;
        border-color: rgba(0, 0, 0, .85);
    }
}

.location-active {
    border-left: 2px solid var(--q-color-primary)!important;
}
.location-inactive {
    border-left: 2px solid var(--q-color-dark)!important;
}
</style>
