<template>
<div class="fit sticky-form-actions edit-panel">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <div :class="'pull-right'">
                    <q-btn class="mr-2" :label="!$q.screen.lt.sm ? translate('delete') : ''" no-caps dense color="danger" @click.prevent="showDeleteLocationPanel(location_id)" icon="delete" flat />
                    <toggle-active @closePanel="closePanel()" :no_label="$q.screen.lt.sm ? true : null" :is_active="is_active" :patch_toggle_route="patch_toggle_route" :item_id="location_id" :entity_type="entity_type" />
                </div>
                <div class="m-1"><q-icon name="edit" class="mr-1" />{{ translate('edit_location') }}</div>
            </template>
            <b-list-group class="list-group-accent">
                <b-form @submit.prevent>
                    <b-list-group-item class="list-group-item-accent-info p-0 pt-2 pb-2">
                        <div class="row m-0">
                            <div class="col-md p-0">
                                <q-input :label="translate('name')" dense square color="primary" type="text" id="name" v-model="location.name" :placeholder="translate('enter_locationName')" />
                            </div>
                            <div class="col-md p-0">
                                <q-input :label="translate('address')" dense square color="primary" type="text" id="address" v-model="location.address" :placeholder="translate('enter_address')" />
                            </div>
                        </div>
                        <div>
                            <q-input :label="translate('description')" :placeholder="translate('enter_description')" v-model="location.description" square dense color="primary" type="textarea" rows="2" />
                        </div>
                        <div class="row m-0">
                            <div class="col p-0">
                                <q-input :label="translate('start_time_open')" color="primary" square dense v-model="location.start_time" emit-value>
                                    <template v-slot:append>
                                        <q-icon name="access_time" class="cursor-pointer">
                                            <q-popup-proxy transition-show="scale" transition-hide="scale">
                                                <q-time class="time-menu" v-model="location.start_time" mask="HH:mm" format24h />
                                            </q-popup-proxy>
                                        </q-icon>
                                    </template>
                                </q-input>
                            </div>
                            <div class="col p-0">
                                <q-input :label="translate('end_time_close')" color="primary" square dense v-model="location.end_time" emit-value>
                                    <template v-slot:append>
                                        <q-icon name="access_time" class="cursor-pointer">
                                            <q-popup-proxy transition-show="scale" transition-hide="scale">
                                                <q-time class="time-menu" v-model="location.end_time" mask="HH:mm" format24h />
                                            </q-popup-proxy>
                                        </q-icon>
                                    </template>
                                </q-input>
                            </div>
                        </div>
                    </b-list-group-item>
                    <b-list-group-item class="list-group-item-accent-info">
                        <custom-images :delete_route="delete_route" :entity_type="entity_type" :get_route="get_route" :post_route="post_route" :item_id="location_id" :item_images="location.images" />
                    </b-list-group-item>
                </b-form>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section side>
                            <q-btn type="submit" no-caps color="info" @click.prevent="editLocation">
                                <q-icon size="1rem" name="check" class="mr-1" /> {{ translate('save') }}
                            </q-btn>
                        </q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import _ from 'lodash'
import CustomImages from '../../../images/CustomImages'
import DeleteLocation from '../forms/DeleteLocation'
import ToggleActive from '../../../template-parts/ToggleActive'

export default {
    name: 'EditLocation',
    props: ['location_id', 'is_active', 'baseUrl', 'entity_type', 'patch_toggle_route'],
    components: {
        'custom-images': CustomImages,
        'toggle-active': ToggleActive,
    },
    computed: {
        post_route: function () {
            return baseUrl + 'locations/images'
        },
        get_route: function () {
            return baseUrl + 'locations'
        },
        delete_route: function () {
            return baseUrl + 'locations/images'
        },
    },
    data: function () {
        return {
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,

            location: {
                name: null,
                address: null,
                start_date: '',
                end_date: '',
            },
            msg: null
        }
    },
    created: function () {
        this.getLocation()
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        toggleActive: function (id) {
            var status = this.location.is_active
            var active = status ? 1 : 0
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            var data = {
                'is_active': active,
            }
            axios.patch(baseUrl + 'locations/' + id + '/is_active/' + active, data, {
                    headers: headers
                })
                .then(response => {
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'toggle_status_' + response.data.msg + key
                    var translated_message = this.translate(message)
                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            status: true
                        })            
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        showDeleteLocationPanel: function (id) {
            const panelInstance = this.$showPanel({
                component: DeleteLocation,
                props: {
                    location_id: id
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.$emit('closePanel', {
                            status: true
                        }) 
                    }
                })
        },
        getLocation: function () {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }

            axios.get(baseUrl + 'locations/' + this.location_id, {
                    headers: headers
                })
                .then(response => {
                    this.location = response.data.item
                    this.location['start_time'] = response.data.item.start_time ? response.data.item.start_time.slice(0, -3) : null
                    this.location['end_time'] = response.data.item.end_time ? response.data.item.end_time.slice(0, -3) : null
                    this.location.images = this.location.images.map(image => {
                        image['image_name'] = baseUrl + image.image_name
                        return image
                    })
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        editLocation: function () {
            var location_id = this.location_id
            var location_name = this.location.name
            var data = {
                'name': location_name,
                'address': this.location.address,
                'description': this.location.description,
                'start_time': this.location.start_time + ':00',
                'end_time': this.location.end_time + ':00'
            }

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }

            axios.put(baseUrl + 'locations/' + location_id, data, {
                    headers: headers
                })
                .then(response => {
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'edit_location_' + response.data.msg + key
                    var translated_message = this.translate(message)
                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            status: true,
                            location: {id: location_id, name: location_name}
                        })                        
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(error => {
                    var msg = error.response.data.msg
                    if (msg != undefined) {
                        this.msg = msg
                        this.$toasted.error('An error has occured', {
                            duration: this.$toasted_duration
                        })
                    }

                })
        },
        closePanel: function () {
            this.$emit('closePanel')
        }
    }
}
</script>
